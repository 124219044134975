import {useMemo} from 'react';
import {useI18n} from '@halp/foundation/i18n';
import {SidebarNav, useBreakpoint} from '@halp/ui';
import {Paths} from '@halp/foundation/Paths';
import {useUser} from '@halp/foundation/Users';
import {useQuery} from '@halp/api/graphql';
import {
	haBookSearch,
	haBriefcase,
	haDocument,
	haHome,
	haTableSearch,
} from '@halp/icons';
import {CoachCard} from '@halp/foundation/Coaching';
import {imgLogo} from '@halp/images';
import {DefaultCoachDocument} from './DefaultCoach.query';

export function StudentSideNav({
	setOpenMenu,
}: {
	setOpenMenu?: (open: boolean) => void;
}) {
	const {t} = useI18n();
	const {data} = useQuery(DefaultCoachDocument);
	const {user} = useUser();
	const qualification = user?.studentProfile?.qualificationComplete;
	const stage = user?.studentProfile?.funnelStage;
	const applicationsDisabled = !qualification || stage === 'GET_STARTED';
	const recommendationDisabled = !qualification || stage === 'GET_STARTED';

	const items = useMemo(() => {
		return [
			{
				text: t('nav.student.home'),
				path: Paths.dashboard.url(),
				icon: haHome,
				iconColor: 'var(--color-grey-12)',
			},
			{
				text: t('nav.student.programs'),
				path: Paths.student.programs.url(),
				disabled: recommendationDisabled,
				disabledMessage: t('nav.student.programs_disabled'),
				icon: haTableSearch,
				iconColor: 'var(--color-grey-12)',
			},
			{
				text: t('nav.student.applications'),
				path: Paths.student.applications.url(),
				disabled: applicationsDisabled,
				disabledMessage: t('nav.student.applications_disabled'),
				icon: haBriefcase,
				iconColor: 'var(--color-grey-12)',
			},
			{
				text: t('nav.student.documents'),
				path: Paths.student.documents.url(),
				disabled: !qualification,
				disabledMessage: t('nav.student.documents_disabled'),
				icon: haDocument,
				iconColor: 'var(--color-grey-12)',
			},
			{
				text: t('nav.student.resources'),
				path: Paths.learning_hub.index,
				target: '_blank',
				disabled: !qualification,
				disabledMessage: t('nav.student.resources_disabled'),
				icon: haBookSearch,
				iconColor: 'var(--color-grey-12)',
			},
		];
	}, [t, recommendationDisabled, applicationsDisabled, qualification]);

	const mobile = useBreakpoint({max: 'md'});

	const coach = user?.studentProfile?.coach || data?.defaultCoach;

	const coachCard = coach && !mobile ? <CoachCard {...coach} /> : null;

	return (
		<SidebarNav
			logo={imgLogo}
			items={items}
			toggle={setOpenMenu}
			alignment="column"
		>
			{coachCard}
		</SidebarNav>
	);
}

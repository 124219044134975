import {useEffect, useRef} from 'react';
import classNames from 'classnames';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
	faExclamationCircle,
	faCheckCircle,
	faCircleQuestion,
} from '@fortawesome/free-solid-svg-icons';
import {CSSVariants} from '@halp/util';
import style from './Toast.module.css';

interface InfoToastProps {
	type: 'info';
	message: string;
}

interface BaseProps {
	type: 'success' | 'error';
	message?: string;
}

export type ToastProps = BaseProps | InfoToastProps;

export function Toast({type, message}: ToastProps) {
	const messageDOM = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (messageDOM.current?.clientHeight) {
			const height = messageDOM.current.clientHeight;
			messageDOM.current.style.height = '0px';
			setTimeout(() => {
				if (messageDOM.current) {
					messageDOM.current.style.height = `${height}px`;
				}
			}, 0);
		}
	});

	const toastContentClasses = classNames(
		style.ToastContent,
		CSSVariants(style, 'Toast', type),
	);

	let icon = faCheckCircle;
	if (type === 'error') {
		icon = faExclamationCircle;
	} else if (type === 'info') {
		icon = faCircleQuestion;
	}

	let toastMessage: string;
	if (message) {
		toastMessage = message;
	} else if (type === 'success') {
		toastMessage = 'Success';
	} else {
		toastMessage = 'Error';
	}

	return (
		<div ref={messageDOM} className={style.ToastMessage}>
			<div className={toastContentClasses}>
				<FontAwesomeIcon icon={icon} className={style.Icon} />
				<div className={style.ToastText}>{toastMessage}</div>
			</div>
		</div>
	);
}

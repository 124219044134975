import {type ReactNode, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleDown, faAngleUp} from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import {
	autoUpdate,
	offset,
	shift,
	safePolygon,
	useClick,
	useDismiss,
	useFloating,
	useHover,
	useInteractions,
	type Placement,
} from '@floating-ui/react';
import {Link} from '../Link';
import {Stack} from '../Stack';
import style from './Dropdown.module.css';

export interface Props {
	toggle: ReactNode;
	hover?: boolean;
	children: ReactNode;
	toggleClassName?: string;
	wrapperClassName?: string;
	toggleBarClassName?: string;
	dropDownType?: 'click' | 'hover';
	type?: 'button' | 'text';
	placement?: Placement;
	caret?: boolean | ReactNode;
	enabled?: boolean;
	xShift?: number;
	yShift?: number;
	closeOnOutsideClick?: boolean;
	onClick?: VoidFunction;
	onOutsideClick?: VoidFunction;
}

export function Dropdown({
	toggle,
	children,
	toggleClassName,
	wrapperClassName,
	toggleBarClassName,
	dropDownType = 'click',
	type = 'text',
	placement = 'bottom-start',
	caret,
	enabled = true,
	xShift = 0,
	yShift = 0,
	closeOnOutsideClick = false,
	onClick,
	onOutsideClick,
}: Props) {
	const [open, setOpen] = useState(false);
	const handleOpenChange = (openVal: boolean, _event?: Event) => {
		setOpen(openVal);
		if (!openVal) {
			onOutsideClick?.();
		}
	};
	const {x, y, refs, strategy, context} = useFloating({
		open,
		onOpenChange: handleOpenChange,
		middleware: [
			offset({mainAxis: 10 + yShift, crossAxis: xShift}),
			shift({padding: 15}),
		],
		placement,
		whileElementsMounted: autoUpdate,
	});

	const caretIcon = open ? faAngleUp : faAngleDown;
	const caretMarkup =
		caret === true ? <FontAwesomeIcon icon={caretIcon} /> : null;
	const toggleClasses = classNames(
		type === 'button' && style.Button,
		toggleClassName,
	);
	const contentClasses = classNames(style.Dropdown, wrapperClassName);

	const {getReferenceProps, getFloatingProps} = useInteractions([
		useHover(context, {
			enabled: dropDownType === 'hover',
			handleClose: safePolygon(),
		}),
		useClick(context, {enabled: dropDownType === 'click', toggle: true}),
		useDismiss(context, {outsidePress: closeOnOutsideClick}),
	]);

	const handleClick = () => {
		if (onClick) {
			onClick();
		} else {
			setOpen(false);
		}
	};

	return (
		<div>
			<Link
				className={toggleClasses}
				ref={refs.setReference}
				{...getReferenceProps()}
			>
				<Stack
					direction="row"
					spacing="xs"
					justifyContent="flex-start"
					className={toggleBarClassName}
				>
					{toggle}
					{caretMarkup}
				</Stack>
			</Link>
			{enabled && open ? (
				<div
					className={contentClasses}
					ref={refs.setFloating}
					onClick={handleClick}
					{...getFloatingProps({
						style: {
							position: strategy,
							top: y ?? '',
							left: x ?? '',
						},
					})}
				>
					{children}
				</div>
			) : null}
		</div>
	);
}

import {type ReactNode, Fragment} from 'react';
import {faAngleUp, faAngleDown} from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import {useRouter} from '../router';
import {Link} from '../Link';
import {Icon} from '../Icon';
import {Paragraph} from '../Typography';
import {useToggle} from '../use-toggle';
import {Image} from '../Image';
import {NavLink} from './components';
import style from './SidebarNav.module.css';
import type {StaticImageData} from '../Image';
import type {IconSize} from './components';
import type {IconProp} from '@fortawesome/fontawesome-svg-core';

interface LinkItem {
	type?: 'link';
	text: string;
	path: string;
	target?: string;
	iconSize?: IconSize;
	iconColor?: string;
	icon?: IconProp;
	subItems?: Item[];
	disabled?: boolean;
	disabledMessage?: string;
	expandable?: boolean;
	textDecoration?: ReactNode;
}

interface BreakItem {
	type: 'break';
}

interface HeadingItem {
	type: 'heading';
	text: string;
}

type Item = LinkItem | BreakItem | HeadingItem;

interface ItemsProps {
	items: Item[];
	toggle?: (open: boolean) => void;
	alignment?: 'row' | 'column';
}

interface Props extends ItemsProps {
	logoURL?: string;
	logo: StaticImageData;
	children?: ReactNode;
}

function Items({items, toggle, alignment}: ItemsProps) {
	const router = useRouter();

	const {toggle: expanded, show, hide} = useToggle();

	return (
		<>
			{items.map((item, index) => {
				if (item.type === 'heading' || item.type === 'break') {
					return (
						<Fragment key={index}>
							<hr />
							{item.type === 'heading' && (
								<Paragraph className={style.HeadingText} spacing="sm">
									{item.text}
								</Paragraph>
							)}
						</Fragment>
					);
				}

				const subItems =
					item.subItems?.length && router?.asPath.startsWith(item.path) ? (
						<div className={style.SubItems}>
							<Items items={item.subItems} toggle={toggle} />
						</div>
					) : null;

				const expandableIcon = expanded ? faAngleUp : faAngleDown;
				const expandButton = (
					<Link onClick={() => (expanded ? hide() : show())}>
						<Icon color="white" icon={expandableIcon} />
					</Link>
				);

				return (
					<Fragment key={`${index}-${item.text}`}>
						<NavLink
							onClick={() => (toggle ? toggle(false) : {})}
							expandable={item.expandable}
							textDecoration={item.expandable ? expandButton : null}
							{...item}
							alignment={alignment}
						/>
						{item.expandable && expanded ? null : subItems}
					</Fragment>
				);
			})}
		</>
	);
}

export function SidebarNav({
	logoURL,
	logo,
	children,
	items,
	toggle,
	alignment,
}: Props) {
	return (
		<div
			className={classNames(
				style.SidebarNav,
				alignment === 'column' && style.SidebarNavColumn,
			)}
		>
			<div
				className={classNames(
					style.Logo,
					alignment === 'column' && style.LogoColumn,
				)}
			>
				<Link href={logoURL || '/'}>
					<Image
						alt="halp logo"
						loading="eager"
						className={style.Logo}
						src={logo}
					/>
				</Link>
			</div>
			<Items items={items} alignment={alignment} toggle={toggle} />
			<div className={style.Children}>{children}</div>
		</div>
	);
}

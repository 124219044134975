import {type ReactNode, useMemo} from 'react';
import {
	useQueryClient as useReactQueryClient,
	QueryClient,
	QueryClientProvider,
	HydrationBoundary,
} from '@tanstack/react-query';
import {GraphQLClient} from 'graphql-request';
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';
import {GRAPHQL_DOMAIN} from './env';
import {GraphQLClientContext} from './graphql';

interface Props {
	dehydratedState: unknown;
	authCallback?: () => Promise<boolean>;
	children: ReactNode;
}

export function useQueryClient() {
	return useReactQueryClient();
}

export function ApiClientProvider({dehydratedState, children}: Props) {
	const queryClient = useMemo(
		() =>
			new QueryClient({
				defaultOptions: {
					queries: {
						refetchOnWindowFocus: false,
					},
				},
			}),
		[],
	);

	const graphQLClient = useMemo(
		() => new GraphQLClient(GRAPHQL_DOMAIN, {credentials: 'include'}),
		[],
	);

	return (
		<GraphQLClientContext.Provider value={graphQLClient}>
			<QueryClientProvider client={queryClient}>
				<HydrationBoundary state={dehydratedState}>
					<ReactQueryDevtools initialIsOpen={false} />
					{children}
				</HydrationBoundary>
			</QueryClientProvider>
		</GraphQLClientContext.Provider>
	);
}

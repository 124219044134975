import type {
	IconDefinition,
	IconName,
	IconPrefix,
} from '@fortawesome/fontawesome-svg-core';

export const haDocument: IconDefinition = {
	prefix: 'ha' as IconPrefix,
	iconName: 'Document' as IconName,
	icon: [
		22,
		26,
		[],
		'',
		'M6.99947 15.1996C6.99947 14.6473 7.44718 14.1996 7.99947 14.1996H15.1995C15.7518 14.1996 16.1995 14.6473 16.1995 15.1996C16.1995 15.7519 15.7518 16.1996 15.1995 16.1996H7.99947C7.44718 16.1996 6.99947 15.7519 6.99947 15.1996Z M7.99947 19.0003C7.44718 19.0003 6.99947 19.448 6.99947 20.0003C6.99947 20.5526 7.44718 21.0003 7.99947 21.0003H11.5995C12.1518 21.0003 12.5995 20.5526 12.5995 20.0003C12.5995 19.448 12.1518 19.0003 11.5995 19.0003H7.99947Z M3.4 0C1.52223 0 0 1.52223 0 3.4V22.6C0 24.4778 1.52224 26 3.4 26H17.8C19.6778 26 21.2 24.4778 21.2 22.6V8.60995C21.2 7.69503 20.8312 6.81858 20.1769 6.17886L14.8486 0.968981C14.2133 0.347824 13.3602 0 12.4716 0H3.4ZM2.04009 3.066C2.18987 2.45407 2.74191 2 3.4 2H8.55116H11.9999V5.8C11.9999 7.67776 13.5221 9.2 15.3999 9.2H19.2V22.6C19.2 23.3732 18.5732 24 17.8 24H3.4C2.62679 24 2 23.3732 2 22.6V3.4C2 3.28489 2.01389 3.17303 2.04009 3.066ZM18.3605 7.2L13.9999 2.9363V5.8C13.9999 6.57321 14.6267 7.2 15.3999 7.2H18.3605Z',
	],
};

import {ApiClientProvider} from '@halp/api';
import {ToastProvider} from '@halp/ui';
import {UserProvider} from '../../Users';
import type {SessionUser} from '../../Users';
import type {ReactNode} from 'react';

interface Props {
	children: ReactNode;
	logoutMessage: string;
	dehydratedState: unknown;
	onUserLoaded?: (user: SessionUser) => void;
}

export function AppProvider({children, dehydratedState, ...props}: Props) {
	return (
		<ToastProvider>
			<ApiClientProvider dehydratedState={dehydratedState}>
				<UserProvider {...props}>{children}</UserProvider>
			</ApiClientProvider>
		</ToastProvider>
	);
}

import {
	type ReactNode,
	useState,
	useCallback,
	useContext,
	createContext,
} from 'react';
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import {Toast} from './Toast';
import style from './Toast.module.css';
import type {ToastProps} from './Toast';

export type ToastType = 'success' | 'error' | 'info';

export interface Toast {
	id: number;
	component: ReactNode;
}

const TOAST_DEFAULT_TIME = 3000;

export type AddToast = (toast: ToastProps) => void;

// eslint-disable-next-line no-empty-function
const ToastContext = createContext<AddToast>(() => {});

export const useToast = () => useContext(ToastContext);

export function ToastProvider({children}: {children: ReactNode}) {
	const [toasts, setToasts] = useState<Toast[]>([]);

	const addToast = useCallback(
		(toast: ToastProps) => {
			const id = Date.now();
			const newToast = {
				id,
				component: <Toast {...toast} />,
			};
			setToasts((prevState) => [...prevState, newToast]);

			setTimeout(() => {
				setToasts(toasts.filter((toast) => toast.id !== id));
			}, TOAST_DEFAULT_TIME);
		},
		// do not include toasts because it is stable and will otherwise cause bad renders
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[setToasts],
	);

	return (
		<ToastContext.Provider value={addToast}>
			{children}
			<div className={style.ToastContainer}>
				<div className={style.ToastList}>
					<TransitionGroup classnames="list">
						{toasts.map((t) => (
							<CSSTransition
								key={t.id}
								timeout={300}
								classNames={{
									enter: style.ToastEnter,
									enterActive: style.ToastEnterActive,
									enterDone: style.ToastEnterDone,
									exitActive: style.ToastExitActive,
								}}
							>
								{t.component}
							</CSSTransition>
						))}
					</TransitionGroup>
				</div>
			</div>
		</ToastContext.Provider>
	);
}

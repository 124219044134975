import classNames from 'classnames';
import {CSSVariants} from '@halp/util';
import style from './Stack.module.css';
import type {ReactNode} from 'react';

export interface Props {
	direction?: 'row' | 'column' | 'row-reverse' | 'column-reverse';
	justifyContent?:
		| 'center'
		| 'flex-start'
		| 'flex-end'
		| 'space-between'
		| 'space-around';
	alignItems?:
		| 'normal'
		| 'center'
		| 'flex-start'
		| 'flex-end'
		| 'stretch'
		| 'baseline'
		| 'end';
	wrap?: 'nowrap' | 'wrap' | 'wrap-reverse';
	spacing?: 'none' | 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
	fullWidth?: boolean;
	children: ReactNode;
	className?: string;
}

export function Stack({
	direction = 'row',
	justifyContent = 'center',
	alignItems = 'center',
	wrap = 'nowrap',
	spacing = 'none',
	fullWidth = false,
	children,
	className,
}: Props) {
	const classes = classNames(
		className,
		style.Stack,
		fullWidth && style.StackFullWidth,
		CSSVariants(style, 'StackDirection', direction),
		CSSVariants(style, 'StackJustifyContent', justifyContent),
		CSSVariants(style, 'StackAlignItems', alignItems),
		CSSVariants(style, 'StackSpacing', spacing),
		CSSVariants(style, 'StackWrap', wrap),
	);

	return <div className={classes}>{children}</div>;
}

import {isBrowser} from '@halp/util';
import type {AnalyticsSnippet} from '@segment/analytics-next';

declare global {
	interface Window {
		analytics: AnalyticsSnippet;
	}
}

// eslint-disable-next-line no-empty-function
const noop = () => {};

export function useAnalytics() {
	const analytics =
		isBrowser() && window.analytics
			? window.analytics
			: {
					load: noop,
					track: noop,
					page: noop,
					sendEvent: noop,
					identify: noop,
				};

	return analytics;
}

import {Stack, Surface, Heading, IFrameRenderer} from '@halp/ui';
import style from './CoachMessages.module.css';
import type {CoachMessage} from './CoachOnboardingMessages';
import type {HTMLProps} from 'react';
import type {Strategy} from '@floating-ui/react';

interface Props {
	close: VoidFunction;
	coachMessages: CoachMessage[];
	getFloatingProps: (
		userProps?: HTMLProps<HTMLElement> | undefined,
	) => Record<string, unknown>;
	setFloating: (node: HTMLElement | null) => void;
	x: number;
	y: number;
	strategy: Strategy;
}

export function CoachMessagesInbox({
	close,
	coachMessages,
	getFloatingProps,
	setFloating,
	x,
	y,
	strategy,
}: Props) {
	return (
		<div
			ref={setFloating}
			onClick={close}
			{...getFloatingProps({
				style: {
					position: strategy,
					top: y ?? '',
					left: x ?? '',
				},
			})}
			className={style.OnboardingMessage}
		>
			<Stack direction="column" spacing="xs">
				{coachMessages.map((message) => {
					return (
						<Surface key={message.text} color="grey">
							{message.title ? (
								<Heading type="h4" spacing={message?.link ? 'md' : 'none'}>
									{message.title}
								</Heading>
							) : null}
							<Heading type="h6" spacing={message?.link ? 'md' : 'none'}>
								{message.text}
							</Heading>
							{message?.link ? (
								<IFrameRenderer
									src={message.link}
									fullSize
									className={style.Frame}
									allowFullScreen
								/>
							) : null}
						</Surface>
					);
				})}
			</Stack>
		</div>
	);
}

import type {
	IconDefinition,
	IconName,
	IconPrefix,
} from '@fortawesome/fontawesome-svg-core';

export const haTextMessage: IconDefinition = {
	prefix: 'ha' as IconPrefix,
	iconName: 'TextMessage' as IconName,
	icon: [
		21,
		21,
		[],
		'',
		'M19.5385 0H4.46154C2 0 0 2.01125 0 4.48664V13.7693C0 16.2447 2 18.256 4.46154 18.256H9.13846L11.6 21.7834C11.6923 21.9072 11.8462 22 12 22C12.1538 22 12.3077 21.9072 12.4 21.7834L14.8615 18.225H19.5385C22 18.225 24 16.2138 24 13.7384V4.4557C24 2.01125 22 0 19.5385 0ZM5.75385 11.0155C4.70769 11.0155 3.87692 10.18 3.87692 9.12799C3.87692 8.07595 4.70769 7.24051 5.75385 7.24051C6.76923 7.24051 7.63077 8.07595 7.63077 9.12799C7.63077 10.18 6.8 11.0155 5.75385 11.0155ZM11.9077 11.0155C10.8615 11.0155 10.0308 10.18 10.0308 9.12799C10.0308 8.07595 10.8615 7.24051 11.9077 7.24051C12.9231 7.24051 13.7846 8.07595 13.7846 9.12799C13.7846 10.18 12.9538 11.0155 11.9077 11.0155ZM18.0615 10.9845C17.0154 10.9845 16.1846 10.1491 16.1846 9.09705C16.1846 8.04501 17.0462 7.20956 18.0615 7.20956C19.0769 7.20956 19.9077 8.07595 19.9385 9.09705C19.9385 10.1491 19.1077 10.9845 18.0615 10.9845Z',
	],
};

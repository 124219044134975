import type {
	IconDefinition,
	IconName,
	IconPrefix,
} from '@fortawesome/fontawesome-svg-core';

export const haBookSearch: IconDefinition = {
	prefix: 'ha' as IconPrefix,
	iconName: 'BookSearch' as IconName,
	icon: [
		37,
		27,
		[],
		'',
		'M33.6034 25.4843L30.2361 22.117C32.0277 19.7241 31.5803 16.2693 29.1273 14.4325V7.06445H11.0881C8.92572 7.06445 7.1665 8.82367 7.1665 10.986V30.5939C7.1665 32.3237 8.57388 33.7311 10.3038 33.7311H29.1273V32.1625H27.5587V29.0252H29.1273C29.1273 28.8348 29.1273 23.8505 29.1273 23.2265L32.4943 26.5935L33.6034 25.4843ZM28.6121 21.6021C27.0831 23.1311 24.5952 23.1311 23.0662 21.6021C21.5372 20.0731 21.5372 17.5852 23.0662 16.0562C24.5951 14.5273 27.0831 14.5271 28.6121 16.0562C30.1411 17.5852 30.1411 20.0731 28.6121 21.6021ZM10.3038 27.4566C9.73252 27.4566 9.19694 27.6108 8.73513 27.8787V18.0448H11.0881V27.4566H10.3038ZM11.0881 16.4762H8.73513V14.9076H11.0881V16.4762ZM11.0881 8.63308V13.339H8.73513V10.986C8.73513 9.68861 9.79061 8.63308 11.0881 8.63308ZM25.99 32.1625H10.3038C8.22224 32.0761 8.22386 29.1109 10.3038 29.0252H25.99V32.1625ZM12.6567 27.4566V8.63308H27.5587V13.6161C22.1063 11.8701 17.9081 18.554 21.957 22.7114C23.4485 24.2029 25.6234 24.6771 27.5587 24.0422V27.4566H12.6567Z M14.2251 10.2031H18.1467V11.7718H14.2251V10.2031Z M14.2251 13.3398H18.1467V14.9085H14.2251V13.3398Z',
	],
};

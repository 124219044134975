import classNames from 'classnames';
import {useRouter} from '../router';
import {Icon} from '../Icon';
import {Stack} from '../Stack';
import {Paragraph} from '../Typography';
import {Link} from '../Link';
import style from './BottomNav.module.css';
import type {IconProp} from '@fortawesome/fontawesome-svg-core';

interface Item {
	text: string;
	icon: IconProp;
	path: string;
	disabled?: boolean;
}

export interface Props {
	items: Item[];
}

export function BottomNav({items}: Props) {
	const router = useRouter();

	return (
		<Stack
			direction="row"
			justifyContent="space-between"
			className={style.BottomNav}
		>
			{items.map((item) => {
				const currentPage = item.path
					? router.asPath.startsWith(item.path)
					: false;

				const disabledClass = classNames(item.disabled && style.DisabledLink);

				return (
					<Link key={item.text} href={item.path} disabled={item.disabled}>
						<Stack direction="column" spacing="xxs">
							<Icon
								icon={item.icon}
								color={currentPage ? 'primary' : 'grey'}
								className={disabledClass}
								size="xl"
							/>
							<Paragraph
								type="subtitle"
								color={currentPage ? 'primary' : 'grey'}
								className={disabledClass}
							>
								{item.text}
							</Paragraph>
						</Stack>
					</Link>
				);
			})}
		</Stack>
	);
}

import {useMemo} from 'react';
import {useI18n} from '@halp/foundation/i18n';
import {Paths} from '@halp/foundation/Paths';
import {BottomNav} from '@halp/ui';
import {useUser} from '@halp/foundation/Users';
import {haTableSearch, haBriefcase, haDocument, haHome} from '@halp/icons';

export function StudentBottomNav() {
	const {t} = useI18n();
	const {user} = useUser();

	const stage = user?.studentProfile?.funnelStage;
	const qualification = user?.studentProfile?.qualificationComplete;
	const recommendationDisabled = !qualification || stage === 'GET_STARTED';
	const applicationsDisabled = !qualification || stage === 'GET_STARTED';

	const items = useMemo(
		() => [
			{
				text: t('nav.student.home'),
				path: Paths.dashboard.url(),
				icon: haHome,
			},
			{
				text: t('nav.student.programs'),
				path: Paths.student.programs.url(),
				icon: haTableSearch,
				disabled: recommendationDisabled,
			},
			{
				text: t('nav.student.applications'),
				path: Paths.student.applications.url(),
				icon: haBriefcase,
				disabled: applicationsDisabled,
			},
			{
				text: t('nav.student.documents'),
				path: Paths.student.documents.url(),
				icon: haDocument,
			},
		],
		[applicationsDisabled, recommendationDisabled, t],
	);

	return <BottomNav items={items} />;
}

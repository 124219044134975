import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import style from './Icon.module.css';
import type {IconProp, SizeProp} from '@fortawesome/fontawesome-svg-core';

const COLOR_MAP = {
	primary: 'var(--icon-color-primary)',
	grey: 'var(--icon-color-grey)',
	white: 'var(--icon-color-white)',
	orange: 'var(--icon-color-orange)',
	yellow: 'var(--icon-color-yellow)',
	black: 'var(--icon-color-black)',
	green: 'var(--icon-color-green)',
	blue: 'var(--icon-color-blue)',
	purple: 'var(--icon-color-purple)',
	red: 'var(--icon-color-red)',
};

export interface Props {
	icon: IconProp;
	color?:
		| 'grey'
		| 'primary'
		| 'white'
		| 'orange'
		| 'yellow'
		| 'black'
		| 'green'
		| 'blue'
		| 'purple'
		| 'red';
	size?: SizeProp;
	hover?: boolean;
	className?: string;
}

export function Icon({icon, color = 'grey', size, hover, className}: Props) {
	const iconColor = COLOR_MAP[color];
	const iconClass = classNames(hover && style.Icon, className);
	return (
		<FontAwesomeIcon
			icon={icon}
			size={size}
			color={iconColor}
			className={iconClass}
		/>
	);
}

import type {
	IconDefinition,
	IconName,
	IconPrefix,
} from '@fortawesome/fontawesome-svg-core';

export const haHome: IconDefinition = {
	prefix: 'ha' as IconPrefix,
	iconName: 'Home' as IconName,
	icon: [
		24,
		26,
		[],
		'',
		'M14.6667 0.991925L22.6666 7.82175C23.0884 8.18916 23.4254 8.63954 23.6552 9.14316C23.8851 9.64677 24.0026 10.1921 24 10.7433V22.1047C24 23.1378 23.5785 24.1286 22.8284 24.8591C22.0783 25.5896 21.0608 26 20 26H4.0001C2.93924 26 1.92183 25.5896 1.17169 24.8591C0.421551 24.1286 0.000126391 23.1378 0.000126391 22.1047V10.7562C-0.00439005 10.2029 0.112184 9.65507 0.342086 9.14908C0.571989 8.64309 0.909944 8.19061 1.33345 7.82175L9.33339 0.991925C10.0668 0.353148 11.0161 0 12 0C12.9839 0 13.9333 0.353148 14.6667 0.991925ZM9.72392 15.9927C9.47387 16.2362 9.33339 16.5665 9.33339 16.9109V23.4031H14.6667V16.9109C14.6667 16.5665 14.5262 16.2362 14.2762 15.9927C14.0261 15.7492 13.687 15.6124 13.3334 15.6124H10.6667C10.3131 15.6124 9.97396 15.7492 9.72392 15.9927ZM20.9428 23.0228C21.1928 22.7793 21.3333 22.449 21.3333 22.1047V10.7433C21.3331 10.5589 21.2925 10.3767 21.2143 10.2088C21.1362 10.0409 21.0222 9.8911 20.88 9.76942L12.88 2.95258C12.6367 2.74441 12.3239 2.62961 12 2.62961C11.6762 2.62961 11.3634 2.74441 11.12 2.95258L3.1201 9.76942C2.97788 9.8911 2.86389 10.0409 2.78573 10.2088C2.70756 10.3767 2.66701 10.5589 2.66677 10.7433V22.1047C2.66677 22.449 2.80725 22.7793 3.0573 23.0228C3.30734 23.2663 3.64648 23.4031 4.0001 23.4031H6.66675V16.9109C6.66675 15.8778 7.08817 14.887 7.83831 14.1565C8.58845 13.4259 9.60586 13.0155 10.6667 13.0155H13.3334C14.3942 13.0155 15.4116 13.4259 16.1618 14.1565C16.9119 14.887 17.3333 15.8778 17.3333 16.9109V23.4031H20C20.3536 23.4031 20.6927 23.2663 20.9428 23.0228Z',
	],
};

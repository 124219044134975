import classNames from 'classnames';
import {CSSVariants} from '@halp/util';
import {Icon} from '../Icon';
import {Link, type LinkProps} from '../Link';
import style from './Button.module.css';
import type React from 'react';
import type {IconProp} from '@fortawesome/fontawesome-svg-core';
import type {
	ReactNode,
	ButtonHTMLAttributes,
	AnchorHTMLAttributes,
} from 'react';

export interface ButtonBaseProps {
	color?:
		| 'primary'
		| 'secondary'
		| 'ghost'
		| 'destructive'
		| 'warning'
		| 'success'
		| 'icon';
	disabled?: boolean;
	href?: LinkProps['href'];
	onClick?: VoidFunction;
	className?: string;
	title?: string;
	type?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
	target?: AnchorHTMLAttributes<HTMLLinkElement>['target'];
}

export interface ButtonDefaultProps extends ButtonBaseProps {
	padding?: 'sm' | 'md';
	thin?: boolean;
	fullWidth?: boolean;
	children?: ReactNode;
	icon?: never;
	iconColor?: never;
}

interface ButtonIconProps extends ButtonBaseProps {
	padding?: never;
	thin?: never;
	fullWidth?: never;
	children?: never;
	icon: IconProp;
	iconColor?: React.ComponentProps<typeof Icon>['color'];
}

export type Props = ButtonDefaultProps | ButtonIconProps;

export function Button({
	color,
	padding = 'sm',
	thin,
	children,
	title,
	disabled,
	className,
	type = 'button',
	icon,
	iconColor = 'grey',
	fullWidth,
	...props
}: Props) {
	let buttonColor = color;
	if (icon && !color) {
		buttonColor = 'icon';
	} else if (!color) {
		buttonColor = 'primary';
	}

	const buttonClasses = classNames(
		style.ButtonDefault,
		CSSVariants(style, 'ButtonColor', buttonColor),
		CSSVariants(style, 'ButtonPadding', padding),
		!thin && !icon && style.ButtonThick,
		icon && style.ButtonIcon,
		className,
	);

	const linkClasses = classNames(fullWidth && style.ButtonFullWidth);

	if (icon) {
		return (
			<Link {...props} className={linkClasses}>
				<button
					role="button"
					type={type}
					disabled={disabled}
					className={buttonClasses}
					title={title}
				>
					<Icon icon={icon} color={iconColor} />
				</button>
			</Link>
		);
	}

	return (
		<Link {...props} className={linkClasses}>
			<button
				role="button"
				type={type}
				disabled={disabled}
				className={buttonClasses}
				title={title}
			>
				{children}
			</button>
		</Link>
	);
}

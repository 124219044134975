import {
	type ReactNode,
	useContext,
	createContext,
	useMemo,
	useEffect,
} from 'react';
import {useQuery} from '@halp/api/graphql';
import {FeatureFlagProvider} from '../../Features';
import {SessionProvider, useSession} from './SessionProvider';
import {SessionUserDocument} from './SessionUser.query';
import type {SessionUserQuery} from './SessionUser.query';

export type SessionUser = NonNullable<SessionUserQuery['session']>;

interface SessionUserContextLoading {
	loading: true;
	user: null;
}

interface SessionUserLoaded {
	loading: false;
	user: SessionUser;
}

export type SessionUserContext = SessionUserContextLoading | SessionUserLoaded;

export const UserContext = createContext<SessionUserContext>({
	loading: true,
	user: null,
});

interface Props {
	children: ReactNode;
	logoutMessage: string;
	onUserLoaded?: (user: SessionUser) => void;
}

export function InnerUserProvider({
	children,
	onUserLoaded,
}: Omit<Props, 'logoutMessage'>) {
	const {hasAuthToken, hasRenewToken, refreshSession} = useSession();
	const {data, isLoading} = useQuery(SessionUserDocument, {
		refetchOnMount: false,
		enabled: hasAuthToken,
	});

	const user: SessionUserContext = useMemo(() => {
		if (isLoading || data?.session === null || data?.session === undefined) {
			return {loading: true, user: null};
		} else {
			return {loading: false, user: data.session as SessionUser};
		}
	}, [data?.session, isLoading]);

	useEffect(() => {
		if (!isLoading) {
			if (user.user) {
				onUserLoaded?.(user.user);
			} else if (hasRenewToken) {
				refreshSession();
			}
		}
	}, [hasRenewToken, user, isLoading, refreshSession, onUserLoaded]);

	return (
		<UserContext.Provider value={user}>
			<FeatureFlagProvider enabledFeatures={user?.user?.enabledFeatures}>
				{children}
			</FeatureFlagProvider>
		</UserContext.Provider>
	);
}

export function UserProvider({logoutMessage, ...props}: Props) {
	return (
		<SessionProvider logoutMessage={logoutMessage}>
			<InnerUserProvider {...props} />
		</SessionProvider>
	);
}

export function useUser() {
	return useContext(UserContext);
}

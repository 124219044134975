import {type ReactNode, useEffect, useState} from 'react';
import classNames from 'classnames';
import {CSSVariants, isBrowser} from '@halp/util';
import {haClose, haHamburger} from '@halp/icons';
import {Icon} from '../Icon';
import {Link} from '../Link';
import {TopNav} from '../TopNav';
import {useScrollPosition} from '../use-scroll-position';
import {useBreakpoint, Breakpoint} from '../Breakpoint';
import style from './Shell.module.css';

interface ShellBaseProps {
	children: ReactNode;
	userMenu?: ReactNode;
	flexNav?: boolean;
	hideTopSideNav?: boolean;
	contentClassName?: string;
	alignment?: 'row' | 'column';
}

interface Props extends ShellBaseProps {
	sideNav?: (
		mobile: boolean,
		setOpenMenu: (value: boolean) => void,
	) => ReactNode;
	bottomNav?: (mobile: boolean) => ReactNode;
}
function SideNav({
	mobile,
	sideNavbar,
	mobileNavOpen,
	closeMobileNav,
	flexNav,
	alignment,
}: {
	sideNavbar?: ReactNode;
	mobile: boolean;
	closeMobileNav: () => void;
	mobileNavOpen: boolean;
	flexNav?: boolean;
	alignment?: 'row' | 'column';
}) {
	if (!sideNavbar) return null;

	const sideNavContainerClasses = classNames(
		mobileNavOpen && style.MobileNavWrapper,
		flexNav && style.NavContainer,
		alignment === 'column' && style.NavContainerColumn,
	);

	const sideNavClasses = classNames(
		style.Nav,
		alignment === 'column' && style.NavColumn,
		mobile && style.MobileNav,
		mobile &&
			CSSVariants(style, 'MobileNavMenu', mobileNavOpen ? 'open' : 'closed'),
	);

	return (
		<div className={sideNavContainerClasses}>
			<div className={sideNavClasses}>
				<Breakpoint max="md">
					<Link onClick={closeMobileNav} className={style.Close}>
						<Icon icon={haClose} />
					</Link>
				</Breakpoint>
				{sideNavbar}
			</div>
		</div>
	);
}

function TopUserNav({
	userMenu,
	scrollActive,
	bottomNavExists,
	openSideNavbar,
	showSideNav,
}: {
	userMenu?: ReactNode;
	scrollActive: boolean;
	showSideNav: boolean;
	bottomNavExists: boolean;
	openSideNavbar: () => void;
}) {
	if (!userMenu) return null;

	return (
		<TopNav active={scrollActive} sticky={bottomNavExists}>
			{showSideNav ? (
				<Breakpoint max="md">
					<Link onClick={openSideNavbar}>
						<Icon icon={haHamburger} />
					</Link>
				</Breakpoint>
			) : null}
			{userMenu}
		</TopNav>
	);
}

function BottomNav({bottomNavbar}: {bottomNavbar?: ReactNode}) {
	if (!bottomNavbar) return null;

	return bottomNavbar;
}
export function Shell({
	hideTopSideNav,
	contentClassName,
	children,
	...props
}: Props) {
	const [isClient, setIsClient] = useState(false);
	const [scrollRef, setScrollRef] = useState<HTMLDivElement | null>(null);
	const [contentRef, setContentRef] = useState<HTMLDivElement | null>(null);
	const [mobileNavOpen, setMobileNavOpen] = useState(false);
	const [scrollActive, setScrollActive] = useState(false);

	const mobile = useBreakpoint({max: 'md'});

	const sideNavbar =
		!hideTopSideNav && props.sideNav
			? props.sideNav(mobile, setMobileNavOpen)
			: undefined;

	const bottomNavbar = props.bottomNav ? props.bottomNav(mobile) : undefined;

	const userMenubar = !hideTopSideNav ? props.userMenu : undefined;

	useScrollPosition(
		({current}) => setScrollActive(current.y < 0),
		[contentRef, scrollRef],
		contentRef,
		scrollRef,
	);

	useEffect(() => {
		if (!isClient) {
			setIsClient(isBrowser());
		}
	}, [isClient]);

	if (!isClient) {
		return null;
	}

	const bottomNavStyles = classNames(
		style.AppContent,
		style.ContentWithBottomNav,
		contentClassName,
	);

	const contentClasses = classNames(
		style.AppContent,
		contentClassName,
		bottomNavbar == null && sideNavbar == null && style.ContentSingleColumn,
		bottomNavbar != null && bottomNavStyles,
	);

	const appClass = classNames(
		style.App,
		!bottomNavbar && !sideNavbar && style.AppSingleColumn,
	);

	return (
		<div className={appClass}>
			<SideNav
				mobile={mobile}
				sideNavbar={sideNavbar}
				closeMobileNav={() => setMobileNavOpen(false)}
				mobileNavOpen={mobileNavOpen}
				{...props}
			/>
			<div className={style.Body} ref={setScrollRef}>
				<TopUserNav
					userMenu={userMenubar}
					scrollActive={scrollActive}
					bottomNavExists={!!bottomNavbar}
					openSideNavbar={() => {
						setMobileNavOpen(true);
					}}
					showSideNav={!!sideNavbar}
				/>
				<div className={contentClasses} ref={setContentRef}>
					{children}
				</div>
				<BottomNav bottomNavbar={bottomNavbar} />
			</div>
		</div>
	);
}

import type {
	IconDefinition,
	IconName,
	IconPrefix,
} from '@fortawesome/fontawesome-svg-core';

export const haBriefcase: IconDefinition = {
	prefix: 'ha' as IconPrefix,
	iconName: 'Briefcase' as IconName,
	icon: [
		25,
		24,
		[],
		'',
		'M13.2328 12.2247C13.2328 11.6724 12.785 11.2247 12.2328 11.2247C11.6805 11.2247 11.2328 11.6724 11.2328 12.2247V12.2357C11.2328 12.788 11.6805 13.2357 12.2328 13.2357C12.785 13.2357 13.2328 12.788 13.2328 12.2357V12.2247Z M17.2005 1.02399C17.8562 1.67963 18.2245 2.56888 18.2245 3.49611V4.98833H20.9689C22.8997 4.98833 24.465 6.55359 24.465 8.48444V13.4623C24.4653 13.4762 24.4653 13.49 24.465 13.5039V19.7169C24.465 21.6478 22.8997 23.213 20.9689 23.213H3.49611C1.56526 23.213 1.49157e-07 21.6478 1.49157e-07 19.7169V13.5039C-0.000288218 13.49 -0.000289172 13.4762 1.49157e-07 13.4623V8.48444C1.49157e-07 6.55359 1.56526 4.98833 3.49611 4.98833H6.24008V3.49611C6.24008 2.56888 6.60842 1.67963 7.26406 1.02399C7.91971 0.368339 8.80896 0 9.73619 0H14.7284C15.6556 0 16.5449 0.368339 17.2005 1.02399ZM8.67828 2.4382C8.95885 2.15763 9.33939 2 9.73619 2H14.7284C15.1252 2 15.5057 2.15763 15.7863 2.4382C16.0669 2.71878 16.2245 3.09932 16.2245 3.49611V4.98833H8.24008V3.49611C8.24008 3.09932 8.3977 2.71878 8.67828 2.4382ZM17.2245 6.99222C17.1946 6.99222 17.165 6.99091 17.1357 6.98833H7.32886C7.29961 6.99091 7.27 6.99222 7.24008 6.99222C7.21016 6.99222 7.18055 6.99091 7.1513 6.98833H3.49611C2.66983 6.98833 2 7.65816 2 8.48444V12.8592C5.19724 14.3692 8.69156 15.154 12.2325 15.154C15.7734 15.154 19.2677 14.3692 22.465 12.8592V8.48444C22.465 7.65816 21.7952 6.98833 20.9689 6.98833H17.3133C17.284 6.99091 17.2544 6.99222 17.2245 6.99222ZM3.49611 21.213C2.66983 21.213 2 20.5432 2 19.7169V15.0524C5.22808 16.4368 8.70899 17.154 12.2325 17.154C15.756 17.154 19.2369 16.4368 22.465 15.0524V19.7169C22.465 20.5432 21.7952 21.213 20.9689 21.213H3.49611Z',
	],
};

import {
	Avatar,
	Link,
	useBreakpoint,
	Heading,
	Stack,
	Image,
	Dropdown,
	Breakpoint,
} from '@halp/ui';
import {imgLogo} from '@halp/images';
import {useI18n} from '../../i18n';
import {useUser} from '../UserProvider';
import {Paths} from '../../Paths';
import style from './UserMenu.module.css';
import {SignOut} from './SignOut';

interface Props {
	children?: React.ReactNode;
}

export function UserMenu({children}: Props) {
	const {t} = useI18n();
	const {user} = useUser();
	const mobile = useBreakpoint({max: 'md'});
	const accountPageURL = mobile
		? Paths.account.index.url()
		: Paths.account.profile.url();

	return (
		<Stack
			direction="row"
			justifyContent={mobile ? 'space-between' : 'flex-end'}
		>
			<Breakpoint max="md">
				<Image
					src={imgLogo}
					loading="eager"
					alt="halp logo"
					className={style.Logo}
				/>
			</Breakpoint>
			<Stack direction="row" spacing="xs">
				{children}
				<Dropdown
					caret
					toggle={<Avatar text={user?.name} size="small" />}
					xShift={0}
					yShift={0}
					closeOnOutsideClick
				>
					<Stack
						direction="column"
						alignItems="flex-start"
						spacing="sm"
						className={style.DropdownContent}
					>
						<Link nav={true} href={accountPageURL}>
							<Heading type="h5" weight="medium">
								{t('navigation.account')}
							</Heading>
						</Link>
						<SignOut />
					</Stack>
				</Dropdown>
			</Stack>
		</Stack>
	);
}

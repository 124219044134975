import {useUser} from '../../Users';
import {useI18n} from '../../i18n';

export interface CoachMessage {
	text: string;
	link?: string;
	title?: string;
}

export type CoachMessageKey =
	| 'SIGNUP'
	| 'GET_STARTED_QUALIFIED'
	| 'DOC_UPLOAD_ONSHORE'
	| 'DOC_UPLOAD_OFFSHORE'
	| 'COACH_INTRO_MWILA'
	| 'COACH_INTRO_ABBY'
	| 'COACH_INTRO_JOSH'
	| 'COACH_INTRO_NAMKA';

export function useCoachOnboardingMessages(): Record<
	CoachMessageKey,
	CoachMessage
> {
	const {t} = useI18n();
	const {user} = useUser();

	return {
		SIGNUP: {
			title: t('coach_messages.signup.title'),
			text: t('coach_messages.signup.description'),
		},
		GET_STARTED_QUALIFIED: {
			title: t('coach_messages.get_started_qualified.title'),
			text: t('coach_messages.get_started_qualified.description'),
			link: 'https://www.loom.com/embed/aac343c108ec4b38a189b4dc349d5bc4',
		},
		DOC_UPLOAD_ONSHORE: {
			title: t('coach_messages.doc_upload_onshore.title'),
			text: t('coach_messages.doc_upload_onshore.description', {
				country: user?.country,
			}),
			link: 'https://www.loom.com/embed/8bb1af596fa749f6b1b27a03c228c0c3',
		},
		DOC_UPLOAD_OFFSHORE: {
			title: t('coach_messages.doc_upload_offshore.title'),
			text: t('coach_messages.doc_upload_offshore.description'),
			link: 'https://www.loom.com/embed/8bb1af596fa749f6b1b27a03c228c0c3',
		},
		COACH_INTRO_MWILA: {
			title: t('coach_messages.coach_intro_mwila.title'),
			text: t('coach_messages.coach_intro_mwila.description'),
			link: 'https://www.loom.com/embed/749c8f7fd4724d9892da0753e483426d',
		},
		COACH_INTRO_ABBY: {
			title: t('coach_messages.coach_intro_abby.title'),
			text: t('coach_messages.coach_intro_abby.description', {
				country: user?.country,
			}),
			link: 'https://www.loom.com/embed/a95ae91536e2423aa0192a8f4e25952c',
		},
		COACH_INTRO_JOSH: {
			title: t('coach_messages.coach_intro_josh.title'),
			text: t('coach_messages.coach_intro_josh.description'),
			link: 'https://www.loom.com/embed/63f7c96e9f6343d693fb4ab83d62752b',
		},
		COACH_INTRO_NAMKA: {
			title: t('coach_messages.coach_intro_namka.title'),
			text: t('coach_messages.coach_intro_namka.description'),
			link: 'https://www.loom.com/embed/0f4a0035087743d8a4f4ff4c3bf29e89',
		},
	};
}

import {useQuery} from '@halp/api/graphql';
import {CoachCard} from '@halp/foundation/Coaching';
import {UserMenu, useUser} from '@halp/foundation/Users';
import {DefaultCoachDocument} from '../StudentSideNav/DefaultCoach.query';

interface Props {
	showCoach?: boolean;
}

export function StudentUserMenu({showCoach}: Props) {
	const {user} = useUser();
	const {data} = useQuery(DefaultCoachDocument);
	const coach = user?.studentProfile?.coach || data?.defaultCoach;

	return (
		<UserMenu>
			{showCoach ? <CoachCard {...coach} hideDetails /> : null}
		</UserMenu>
	);
}

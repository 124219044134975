import {seoURL} from '@halp/util';
import {Domains} from './domains';
import {Path} from './path';

export const USER_PATHS = {
	show: new Path(
		(
			id: number | string,
			seo?: string | null,
			type?: 'coach' | 'admin' | 'partner' | 'student',
		) => {
			if (type === 'admin') {
				return new Path(`/user/${id}${seoURL(id, seo)}`, Domains.admin).url();
			} else if (type === 'coach') {
				return new Path(`/user/${id}${seoURL(id, seo)}`, Domains.coach).url();
			}
			return new Path('/').url();
		},
	),
	overview: {
		index: new Path(
			(
				prefix: string,
				id: string,
				name?: string | null,
				type?: 'coach' | 'admin' | 'partner' | 'student',
			) => {
				if (type === 'admin') {
					return new Path(
						`${prefix}/user/${id}-${name}/overview`,
						Domains.admin,
					).url();
				} else if (type === 'coach') {
					return new Path(
						`${prefix}/user/${id}-${name}/overview`,
						Domains.coach,
					).url();
				}
				return new Path('/').url();
			},
		),
		programRecommendations: {
			index: new Path(
				(
					prefix: string,
					id: string,
					name?: string | null,
					type?: 'coach' | 'admin' | 'partner' | 'student',
				) => {
					if (type === 'admin') {
						return new Path(
							`${prefix}/user/${id}-${name}/recommendations`,
							Domains.admin,
						).url();
					} else if (type === 'coach') {
						return new Path(
							`${prefix}/user/${id}-${name}/recommendations`,
							Domains.coach,
						).url();
					}
					return new Path('/').url();
				},
			),
			add: new Path(
				(
					prefix: string,
					id: string,
					name?: string | null,
					type?: 'coach' | 'admin' | 'partner' | 'student',
				) => {
					if (type === 'admin') {
						return new Path(
							`${prefix}/user/${id}-${name}/recommendations/add-recommendations`,
							Domains.admin,
						).url();
					} else if (type === 'coach') {
						return new Path(
							`${prefix}/user/${id}-${name}/recommendations/add-recommendations`,
							Domains.coach,
						).url();
					}
					return new Path('/').url();
				},
			),
		},
		documents: new Path(
			(
				prefix: string,
				id: string,
				name?: string | null,
				type?: 'coach' | 'admin' | 'partner' | 'student',
			) => {
				if (type === 'admin') {
					return new Path(
						`${prefix}/user/${id}-${name}/documents`,
						Domains.admin,
					).url();
				} else if (type === 'coach') {
					return new Path(
						`${prefix}/user/${id}-${name}/documents`,
						Domains.coach,
					).url();
				}
				return new Path('/').url();
			},
		),
		applications: {
			index: new Path(
				(
					prefix: string,
					id: string,
					name?: string | null,
					type?: 'coach' | 'admin' | 'partner' | 'student',
				) => {
					if (type === 'admin') {
						return new Path(
							`${prefix}/user/${id}-${name}/applications`,
							Domains.admin,
						).url();
					} else if (type === 'coach') {
						return new Path(
							`${prefix}/user/${id}-${name}/applications`,
							Domains.coach,
						).url();
					}
					return new Path('/').url();
				},
			),
			show: new Path(
				(
					prefix: string,
					id: string,
					applicationId: string,
					name?: string | null,
					type?: 'coach' | 'admin' | 'partner' | 'student',
					// eslint-disable-next-line max-params
				) => {
					if (type === 'admin') {
						return new Path(
							`${prefix}/user/${id}-${name}/applications/${applicationId}`,
							Domains.admin,
						).url();
					} else if (type === 'coach') {
						return new Path(
							`${prefix}/user/${id}-${name}/applications/${applicationId}`,
							Domains.coach,
						).url();
					}
					return new Path('/').url();
				},
			),
		},
		qualification: new Path(
			(
				prefix: string,
				id: string,
				name?: string | null,
				type?: 'coach' | 'admin' | 'partner' | 'student',
			) => {
				if (type === 'admin') {
					return new Path(
						`${prefix}/user/${id}-${name}/qualification`,
						Domains.admin,
					).url();
				} else if (type === 'coach') {
					return new Path(
						`${prefix}/user/${id}-${name}/qualification`,
						Domains.coach,
					).url();
				}
				return new Path('/').url();
			},
		),
	},
	students: {
		all: new Path((type?: 'coach' | 'admin' | 'partner' | 'student') => {
			if (type === 'admin') {
				return new Path(`/students/all`, Domains.admin).url();
			} else if (type === 'coach') {
				return new Path(`/students/all`, Domains.coach).url();
			}

			return new Path('/').url();
		}),
		profileComplete: new Path(
			(type?: 'coach' | 'admin' | 'partner' | 'student') => {
				if (type === 'admin') {
					return new Path(`/students/profile_complete`, Domains.admin).url();
				} else if (type === 'coach') {
					return new Path(`/students/profile_complete`, Domains.coach).url();
				}

				return new Path('/').url();
			},
		),
		verified: new Path((type?: 'coach' | 'admin' | 'partner' | 'student') => {
			if (type === 'admin') {
				return new Path(`/students/verified`, Domains.admin).url();
			} else if (type === 'coach') {
				return new Path(`/students/verified`, Domains.coach).url();
			}

			return new Path('/').url();
		}),
	},
	coaching: {
		all: new Path(
			(id: string, type?: 'coach' | 'admin' | 'partner' | 'student') => {
				if (type === 'admin') {
					return new Path(`/coaching/${id}/all`, Domains.admin).url();
				} else if (type === 'coach') {
					return new Path(`/coaching/all`, Domains.coach).url();
				}

				return new Path('/').url();
			},
		),
		getStarted: new Path(
			(id: string, type?: 'coach' | 'admin' | 'partner' | 'student') => {
				if (type === 'admin') {
					return new Path(`/coaching/${id}/start`, Domains.admin).url();
				} else if (type === 'coach') {
					return new Path(`/coaching/start`, Domains.coach).url();
				}
				return new Path('/').url();
			},
		),
		discover: new Path(
			(id: string, type?: 'coach' | 'admin' | 'partner' | 'student') => {
				if (type === 'admin') {
					return new Path(`/coaching/${id}/discover`, Domains.admin).url();
				} else if (type === 'coach') {
					return new Path(`/coaching/discover`, Domains.coach).url();
				}
				return new Path('/').url();
			},
		),
		prep: new Path(
			(id: string, type?: 'coach' | 'admin' | 'partner' | 'student') => {
				if (type === 'admin') {
					return new Path(`/coaching/${id}/prep`, Domains.admin).url();
				} else if (type === 'coach') {
					return new Path(`/coaching/prep`, Domains.coach).url();
				}
				return new Path('/').url();
			},
		),
		apply: new Path(
			(id: string, type?: 'coach' | 'admin' | 'partner' | 'student') => {
				if (type === 'admin') {
					return new Path(`/coaching/${id}/apply`, Domains.admin).url();
				} else if (type === 'coach') {
					return new Path(`/coaching/apply`, Domains.coach).url();
				}
				return new Path('/').url();
			},
		),
		accepted: new Path(
			(id: string, type?: 'coach' | 'admin' | 'partner' | 'student') => {
				if (type === 'admin') {
					return new Path(`/coaching/${id}/accepted`, Domains.admin).url();
				} else if (type === 'coach') {
					return new Path(`/coaching/accepted`, Domains.coach).url();
				}
				return new Path('/').url();
			},
		),
		visa: new Path(
			(id: string, type?: 'coach' | 'admin' | 'partner' | 'student') => {
				if (type === 'admin') {
					return new Path(`/coaching/${id}/visa`, Domains.admin).url();
				} else if (type === 'coach') {
					return new Path(`/coaching/visa`, Domains.coach).url();
				}
				return new Path('/').url();
			},
		),
		arrive: new Path(
			(id: string, type?: 'coach' | 'admin' | 'partner' | 'student') => {
				if (type === 'admin') {
					return new Path(`/coaching/${id}/arrive`, Domains.admin).url();
				} else if (type === 'coach') {
					return new Path(`/coaching/arrive`, Domains.coach).url();
				}
				return new Path('/').url();
			},
		),
		placed: new Path(
			(id: string, type?: 'coach' | 'admin' | 'partner' | 'student') => {
				if (type === 'admin') {
					return new Path(`/coaching/${id}/placed`, Domains.admin).url();
				} else if (type === 'coach') {
					return new Path(`/coaching/placed`, Domains.coach).url();
				}
				return new Path('/').url();
			},
		),
		archived: new Path(
			(id: string, type?: 'coach' | 'admin' | 'partner' | 'student') => {
				if (type === 'admin') {
					return new Path(`/coaching/${id}/archived`, Domains.admin).url();
				} else if (type === 'coach') {
					return new Path(`/coaching/archived`, Domains.coach).url();
				}
				return new Path('/').url();
			},
		),
	},
};

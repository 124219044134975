import classNames from 'classnames';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {CSSVariants} from '@halp/util';
import {Image} from '../Image';
import style from './Avatar.module.css';
import type {IconProp} from '@fortawesome/fontawesome-svg-core';

export interface Props {
	icon?: IconProp;
	image?: string | null;
	alt?: string;
	text?: string | null;
	borderRadius?: 'squared' | 'circle';
	size?: 'small' | 'medium' | 'large';
	color?: 'primary' | 'secondary' | 'dark-purple';
	className?: string;
}

export function Avatar({
	icon,
	image,
	alt,
	text,
	size,
	borderRadius = 'circle',
	color = 'primary',
	className,
}: Props) {
	const classes = classNames(
		style.Avatar,
		icon != null && style.AvatarIcon,
		CSSVariants(style, 'AvatarSize', size),
		CSSVariants(style, 'AvatarColor', color),
		CSSVariants(style, 'AvatarBorderRadius', borderRadius),
		className,
	);

	if (image) {
		return <Image alt={alt ?? 'avatar'} className={classes} src={image} />;
	} else if (icon) {
		return (
			<div className={classes}>
				<FontAwesomeIcon icon={icon} />
			</div>
		);
	} else {
		const initial = text
			? text[0]
			: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[Math.floor(Math.random() * 26)];
		return <div className={classes}>{initial}</div>;
	}
}
